<template>

  <div class="CC-header">
    <!-- Background Header -->
    <i
      class="
        imcc-headerbg
        imCC-Background-Cover
        mb-5
        d-flex
        CC-bg-header
        position-relative
      "
    ></i>
 
    <div class="position-absolute d-flex justify-content-center CC-head-logo">
      <!-- header Main Circle -->
      <i class="cCC-bg-main CC-circle-bg position-absolute uCC-border-h-50"></i>
      <!-- Header Logo -->
      <div class="d-flex justify-content-center mt-2">
        <i
          class="
            
            imCC-Background-Contain
            d-flex
            CC-headerLogo
            position-absolute
            wow
            animate__animated animate__bounceInDown
          "
          :class="$i18n.locale === 'ar' ? 'iCC-talabtkomlogo' : 'iCC-talabtkomlogo-en'"
        ></i>
      </div>
    </div>
    <!-- Header Data  (Icon - text )-->

    <div
      class="
        header-text-main
        position-absolute
        text-center
        justify-content-center
        align-items-center
        d-flex
        flex-column
        uCC-gap-32
        w-100
        CC-header-data
      "
    >
      <!-- Header text  -->
      <div class="w-100 position-absolute CC-text-header">
        <p
          class="
            fCC-SemiBold-sE
            m-0
            cCC-text-secondary
            wow
            animate__animated animate__flipInX
          "
        >
          {{ $t("Deliver") }}
          <b class="cCC-text-main">{{ $t("fast") }}</b>
          {{ $t("atThe") }}
        </p>
        <p
          class="
            fCC-SemiBold-sE
            m-0
            cCC-text-secondary
            wow
            animate__animated animate__flipInX
          "
        >
          {{ $t("lowest") }}
          <b class="cCC-text-main">{{ $t("price") }}</b>
        </p>
      </div>
      <!-- Store Icons -->
      <div class="d-flex CC-store-icon uCC-gap-16 position-absolute">
        <div class="flex-row" v-for="data in DownloadData" :key="data">
          <a :href="data.link" target="_blank">
            <i
              :class="data.icon"
              class="
                d-flex
                imCC-Background-Contain
                CC-icons-header
                uCC-Click
                wow
                animate__animated
                animation
              "
            ></i
          ></a>
        </div>
      </div>
      <!-- header img -->
      <div
        class="d-flex justify-content-center position-absolute CC-header-photo"
      >
        <i
          class="imcc-headerimg d-flex CC-header-img imCC-Background-Contain"
        ></i>
        <i
          class="
            imcc-icon-one
            d-flex
            CC-tomato
            position-absolute
            imCC-Background-Contain
            cvvc
          "
        ></i>
        <i
          class="
            imcc-icon-two
            d-flex
            CC-b-paper
            position-absolute
            imCC-Background-Contain
            cvvc
          "
        ></i>
        <i
          class="
            imcc-icon-three
            d-flex
            CC-s-paper
            position-absolute
            imCC-Background-Contain
            cvvc
          "
        ></i>
      </div>
    </div>
  </div>
</template>
<script>
import WOW from "wow.js";
new WOW().init();
export default {
  name: "HeaderTalabtkom",

  data() {
    return {
      DownloadData: [
        {
          
          icon: "iCC-play-store animate__fadeInDown",
          link: "https://play.google.com/store/apps/details?id=com.lundev.talabatcom",
        },
        {
          icon: "iCC-apple-store animate__fadeInDown",
          link: "https://apps.apple.com/us/app/talabatcom/id1612445220",
        },
      ],
    };
  },

  props: {
    msg: String,
  },
};
</script>
<style>
/* Background Header  */

select {
  z-index: 2;
}
.CC-header {
  height: 80vh;
}
.CC-bg-header {
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
}
/* header Main Circle */
.CC-head-logo {
  height: 25vh;
  width: 100%;
  top: 0 !important;
}
.CC-circle-bg {
  width: 35vw;
  height: 35vw;
  top: -25vw;
}
/* Header Photo */
.CC-header-photo {
  top: 30vh;
}
.CC-tomato {
  left: 0;
  bottom: 150px;
  z-index: 0;
  width: 80px;
  height: 50px;
}
.CC-s-paper {
  width: 27px;
  height: 24px;
  right: 0;
  top: 30px;
  z-index: 0;
}
.CC-b-paper {
  width: 80px;
  height: 50px;
  right: 0;
  bottom: 30px;
  z-index: 0;
}
/* header Data (icon - text) */
.CC-header-data {
  top: 24vh;
}
/* App icons */
.CC-store-icon {
  top: 25vh;
}
/* header text */
.CC-text-header {
  top: 0;
}
/* Header Logo */
.CC-headerLogo {
  z-index: 3;
  width: 250px;
  height: 120px;
  top: 15%;
}
/* Header icons */
.CC-store-icon i {
  height: 39px;
  width: 130px;
}
/* Header Img */
.CC-header-img {
  width: 300px;
  height: 387px;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .CC-header {
    height: 38vh;
  }
  .CC-bg-header {
    top: 0;
    left: 0;
    width: 100%;
    height: 35vh;
  }
  .CC-headerLogo {
    z-index: 3;
    width: 65px;
    height: 30px;
    top: 0%;
  }
  .CC-header-data {
    top: 8vh;
  }
  .CC-store-icon {
    top: 8vh;
  }
  .CC-store-icon i {
    height: 39px;
    width: 80px;
  }
  .CC-header-photo {
    top: 13vh;
  }
  .CC-header-img {
    width: 200px;
    height: 187px;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .CC-header {
    height: 60vh;
  }
  .CC-bg-header {
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
  }
  .CC-headerLogo {
    z-index: 3;
    width: 80px;
    height: 40px;
    top: 0%;
  }
  .CC-header-data {
    top: 15vh;
  }
  .CC-store-icon {
    top: 12vh;
  }
  .CC-store-icon i {
    height: 39px;
    width: 80px;
  }
  .CC-header-photo {
    top: 20vh;
  }
  .CC-header-img {
    width: 200px;
    height: 187px;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .CC-header {
    height: 55vh;
  }
  .CC-bg-header {
    top: 0;
    left: 0;
    width: 100%;
    height: 40vh;
  }
  .CC-headerLogo {
    z-index: 3;
    width: 100px;
    height: 60px;
    top: 5%;
  }
  .CC-header-data {
    top: 10vh;
  }
  .CC-store-icon {
    top: 12vh;
  }
  .CC-store-icon i {
    height: 45px;
    width: 100px;
  }
  .CC-header-photo {
    top: 18vh;
  }
  .CC-header-img {
    width: 350px;
    height: 300px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .CC-header {
    height: 50vh;
  }
  .CC-bg-header {
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
  }
  .CC-headerLogo {
    z-index: 3;
    width: 180px;
    height: 60px;
    top: 5%;
  }
  .CC-header-data {
    top: 10vh;
  }
  .CC-store-icon {
    top: 15vh;
  }
  .CC-store-icon i {
    height: 50px;
    width: 200px;
  }
  .CC-header-photo {
    top: 20vh;
  }
  .CC-header-img {
    width: 380px;
    height: 395px;
  }
}
/* 
// Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .CC-header {
    height: 80vh;
  }
  .CC-bg-header {
    top: 0;
    left: 0;
    width: 100%;
    height: 80vh;
  }
  .CC-headerLogo {
    z-index: 3;
    width: 285px;
    height: 100px;
    top: 10%;
  }
  .CC-header-data {
    top: 25vh;
  }
  .CC-store-icon {
    top: 25vh;
  }
  .CC-store-icon i {
    height: 50px;
    width: 120px;
  }
  .CC-header-photo {
    top: 30vh;
  }
  .CC-header-img {
    width: 380px;
    height: 380px;
  }
}
</style>