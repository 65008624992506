export default {
  "Deliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توصيل "])},
  "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أسرع "])},
  "atThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لطلبك "])},
  "lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بأقل "])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر "])},
  "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لماذا تختار تطبيق "])},
  "Talabatcom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتكم؟"])},
  "enjoy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمتع بتجربة تجهيز الطلب من أى مطعم او مقهى او متجر متوفر فى التطبيق "])},
  "enjoyand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل فى تجربة توصيل الطلبات المحسنة والتى تعطيك مايفوق توقعاتك "])},
  "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جودة التوصيل"])},
  "guarantee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نضمن لك سرعة الوصول وتحديد الزمن المتوقع عند استلام الطلب من المطعم"])},
  "Tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتبع الطلب"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تتبع الطلب على الخريطة من لحظة ارسال الطلب الى المطعم الى ان يصل اليك"])},
  "RestaurantsAndMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مطاعم وآكثر"])},
  "applicationProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وفر التطبيق مطاعم ومقاهي ومتاجر مختلفة وقريبة من مكانك ومن كافة التصنيفات المتوفرة"])},
  "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمتع بتوصياتنا"])},
  "smartRecommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يقدم التطبيق توصيات ذكية لك بناء على تفضيلاتك فى المطاعم ويساعدك فى المناطق الجديده"])},
  "UploadTalabatcom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمل تطبيق طلباتكم ليصلك طلبك الى باب البيت فى اسرع وقت"])},
  "HowDoes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يعمل تطبيق"])},
  "TalabatcomOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق طلباتكم يوفر العديد من المزايا التى تجعل خدمات التوصيل "])},
  "TalabatcomOffersand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكثر فاعلية وبأسعار مناسبة حمل التطبيق الآن لتستمتع بتجربة فريدة ومتميزة "])},
  "CreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انشاء حساب او تسجيل دخول"])},
  "CreateAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بأنشاء حساب اذا كنت تقوم بفتح التطبيق لأول مرة او قم بتسجيل الدخول اذا كان لديك حساب مسبق"])},
  "favoriteRestaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المطعم المفضل"])},
  "availableRestaurants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتصفح المطاعم المتاحة ثم اختر المطعم الذى تريد عمل طلب منه لتصفح جميع المأكولات والمشروبات"])},
  "AddToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافة الى عربة التسوق"])},
  "shoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد اختيارك للوجبات سوف تتضاف الى عربة التسوق حيث يمكنك مراجعة الطلب او إضافة اعداد اخرى او مسح وجبات"])},
  "SendOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارسال الطلب"])},
  "completeTheOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد الانتهاء من مراجعة الطلب سوف تقوم بتأكيد بياناتك الشخصية ومراجعتها لاستكمال الطلب"])},
  "OrderTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتبع الطلب"])},
  "trackTheOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يمكنك تتبع الطلب من لحظة استلام المطعم للطلب .. ثم تحضيره ثم استلام السائق الى لحظة وصوله اليك من خلال الخريطة"])},
  "ApplicationEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" تقييم الطلب"])},
  "evaluate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعد استلام الطلب يمكنك تقيم التجربة بالكامل وتحميل ملف الفاتورة"])},
  "Appreciate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يسعدنا ان تقوم بتجربة تطبيقنا"])},
  "haveAstore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لديك متجر وتريد ان تكون شريكا لنا لتصل"])},
  "haveAstore1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الى اكثر عدد ممكن من المستخدمين؟"])},
  "JoinUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انضم الينا"])},
  "StoreFullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المتجر كامل"])},
  "StoreType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجال عمل المتجر"])},
  "Branches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفروع"])},
  "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة"])},
  "chooseCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر المدينة"])},
  "StoreOwnerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم صاحب المتجر"])},
  "StoreAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان المتجر"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم التواصل"])},
  "EmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البريد الالكتروني"])},
  "StoreFullNameErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المتجر مطلوب"])},
  "StoreTypeErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجال عمل المتجر مطلوب"])},
  "BranchesErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عدد الفروع مطلوب"])},
  "CityErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة مطلوب"])},
  "StoreOwnerNameErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم صاحب المتجر مطلوب"])},
  "StoreAddressErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان المتجر مطلوب"])},
  "ContactErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم التواصل مطلوب"])},
  "maxlength2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرقم غير صحيح"])},
  "minlength3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اقل عدد 3 حروف"])},
  "maxlength25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الاقصي 25 حرف "])},
  "maxlength50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصي 50 حرف "])},
  "maxlength150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحد الأقصي 150 حرف "])},
  "EmailErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأيميل غير صحيح"])},
  "EmailAddressErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" البريد الالكتروني مطلوب"])},
  "SentData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ارسال البيانات"])},
  "textend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["newdir"])},
  "mobreve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC-justify-start animate__fadeInLeft"])},
  "Riyadh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرياض"])},
  "Jeddah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جدة"])},
  "Mecca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مكة"])},
  "Medina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المدينة المنورة"])},
  "AlDammām": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدمام"])},
  "AlHufuf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحوف"])},
  "Tabuk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تابوك "])},
  "KhamisMushait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خميس مشيط"])},
  "Najran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نجران"])},
  "AlMubarraz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبرز  "])},
  "Abha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابها"])},
  "AlJawf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجوف"])},
  "Arar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرعر"])},
  "Jizan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جيزان"])},
  "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCC-drop-down"])},
  "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ييييي"])}
}