<template>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div
        class="
          modal-content
          cCC-bg-modal
          CC-up-modal
          d-flex
          justify-content-center
          align-items-center
        "
      >
        <div class="w-100 text-end">
          <button
            type="button"
            class="btn-close position-absolute CC-head-close d-lg-none"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click.prevent="onClose"
          ></button>
        </div>

        <div
          class="
            modal-body
            d-flex
            flex-column
            uCC-gap-16
            align-items-center
            w-100
            p-5
          "
        >
          <form action="" method="post" name="contact-form" class="w-100">
            <div class="w-100 mt-3" dir="rtl">
              <label
                for="post"
                class="form-label fCC-Regular-sB d-flex uCC-gap-8"
                dir="rtl"
                :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
              >
                {{ $t("StoreFullName") }}
                <i class="iCC-star-toggle d-flex CC-star-toggle"></i>
              </label>
              <div class="w-100">
                <input
                  type="text"
                  class="form-control"
                  v-model="companyName"
                  @keydown="checkKeyDownAlphaNumeric($event)"
                  :class="
                    $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                  "
                />
                <span
                  class="error-feedback"
                  :class="$i18n.locale === 'ar' ? 'modaldir' : 'CC-reverse'"
                  v-if="v$.companyName.$error"
                >
                  {{ v$.companyName.$errors[0].$message }}
                </span>
              </div>
            </div>
            <div class="w-100 mt-3" dir="rtl">
              <label
                for="post"
                dir="rtl"
                class="form-label fCC-Regular-sB d-flex text-center uCC-gap-8"
                :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
              >
                {{ $t("StoreType") }}
                <i class="iCC-star-toggle d-flex CC-star-toggle"></i
              ></label>
              <input
                type="text"
                class="form-control CC-input"
                v-model="companyType"
                @keydown="checkKeyDownAlphaNumeric($event)"
                :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
              />
              <span
                class="error-feedback"
                :class="$i18n.locale === 'ar' ? 'modaldir' : 'CC-reverse'"
                v-if="v$.companyType.$error"
              >
                {{ v$.companyType.$errors[0].$message }}
              </span>
            </div>
            <div class="d-flex row mt-3">
              <span class="w-50 col-lg-6 col">
                <label
                  for=""
                  class="form-label fCC-Regular-sB d-flex uCC-gap-8"
                  :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
                >
                  {{ $t("City") }}
                  <i class="iCC-star-toggle d-flex CC-star-toggle"></i
                ></label>
                <select
                  name="countrys"
                  class="form-control imCC-Background-model justify-content-end"
                  :class="[
                    icon,
                    $i18n.locale === 'en'
                      ? 'imCC-Background-model-r'
                      : 'imCC-Background-model-l',
                  ]"
                  id="countrys"
                  v-model="countrys"
                >
                  <option
                    selected
                    disabled
                    value=""
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("chooseCity") }}
                  </option>

                  <option
                    value="Riyadh"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("Riyadh") }}
                  </option>
                  <option
                    value="Jeddah"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("Jeddah") }}
                  </option>
                  <option
                    value="Mecca"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("Mecca") }}
                  </option>
                  <option
                    value="Medina"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("Medina") }}
                  </option>
                  <option
                    value="AlDammām"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("AlDammām") }}
                  </option>
                  <option
                    value="AlHufuf"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("AlHufuf") }}
                  </option>
                  <option
                    value="Tabuk"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("Tabuk") }}
                  </option>
                  <option
                    value="Khamis Mushait "
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("KhamisMushait") }}
                  </option>
                  <option
                    value="Najran"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("Najran") }}
                  </option>
                  <option
                    value="AlMubarraz"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("AlMubarraz") }}
                  </option>
                  <option
                    value="Abha"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("Abha") }}
                  </option>
                  <option
                    value="AlJawf"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("AlJawf") }}
                  </option>
                  <option
                    value="Arar"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("Arar") }}
                  </option>
                  <option
                    value="Jizan"
                    dir="rtl"
                    :class="
                      $i18n.locale === 'en' ? 'CC-dir-city' : 'CC-reverse-city'
                    "
                  >
                    {{ $t("Jizan") }}
                  </option>
                </select>
              </span>
              <span class="w-50 col-lg-6 col" dir="rtl">
                <label
                  for=""
                  class="form-label fCC-Regular-sB d-flex uCC-gap-8"
                  :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
                >
                  {{ $t("Branches") }}
                  <i class="iCC-star-toggle d-flex CC-star-toggle"></i
                ></label>
                <input
                  class="form-control CC-input"
                  v-model.number="branches"
                  type="number"
                  :min="1"
                  :max="99"
                  :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
                />
                <span
                  class="error-feedback"
                  :class="$i18n.locale === 'ar' ? 'modaldir' : 'CC-reverse'"
                  v-if="v$.branches.$error"
                >
                  {{ v$.branches.$errors[0].$message }}
                </span>
              </span>
            </div>
            <div class="w-100 mt-3" dir="rtl">
              <label
                for=""
                class="form-label fCC-Regular-sB d-flex uCC-gap-8"
                :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
              >
                {{ $t("StoreOwnerName") }}
                <i class="iCC-star-toggle d-flex CC-star-toggle"></i
              ></label>
              <input
                type="text"
                class="form-control CC-input"
                v-model="name"
                @keydown="checkKeyDownAlphaNumeric($event)"
                :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
              />
              <span
                class="error-feedback"
                :class="$i18n.locale === 'ar' ? 'modaldir' : 'CC-reverse'"
                v-if="v$.name.$error"
              >
                {{ v$.name.$errors[0].$message }}
              </span>
            </div>
            <div class="w-100 mt-3" dir="rtl">
              <label
                for=""
                class="form-label fCC-Regular-sB d-flex uCC-gap-8"
                :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
              >
                {{ $t("StoreAddress") }}
                <i class="iCC-star-toggle d-flex CC-star-toggle"></i
              ></label>
              <input
                type="text"
                class="form-control CC-input"
                v-model="adress"
                :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
              />
              <span
                class="error-feedback"
                :class="$i18n.locale === 'ar' ? 'modaldir' : 'CC-reverse'"
                v-if="v$.adress.$error"
              >
                {{ v$.adress.$errors[0].$message }}
              </span>
            </div>
            <div class="d-flex row mt-3 justify-content-center">
              <span class="col-lg-6 col" dir="rtl">
                <label
                  for=""
                  class="form-label fCC-Regular-sB d-flex uCC-gap-8"
                  :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
                >
                  {{ $t("EmailAddress") }}
                  <i class="iCC-star-toggle d-flex CC-star-toggle"></i
                ></label>
                <input
                  type="email"
                  class="form-control CC-input"
                  v-model="email"
                  :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
                />
                <span
                  class="error-feedback"
                  :class="$i18n.locale === 'ar' ? 'modaldir' : 'CC-reverse'"
                  v-if="v$.email.$error"
                >
                  {{ v$.email.$errors[0].$message }}
                </span>
              </span>
              <span class="col-lg-6 col" dir="rtl">
                <label
                  for=""
                  class="form-label fCC-Regular-sB d-flex uCC-gap-8"
                  :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
                >
                  {{ $t("Contact") }}
                  <i class="iCC-star-toggle d-flex CC-star-toggle"></i
                ></label>
                <input
                  type="text"
                  class="form-control CC-input"
                  @input="acceptNumber"
                  v-model="number"
                  :class="$i18n.locale === 'ar' ? 'ar-lable' : 'CC-reverse'"
                />
                <span
                  class="error-feedback"
                  :class="$i18n.locale === 'ar' ? 'modaldir' : 'CC-reverse'"
                  v-if="v$.number.$error"
                >
                  {{ v$.number.$errors[0].$message }}
                </span>
              </span>
            </div>
          </form>
          <button
            type="button"
         
            @click="sendData()"
            class="
              fCC-Bold-sA
              w-100
              cCC-bg-main
              CC-send-button
              uCC-border-t-10
              cCC-text-white
              mt-3
              CC-submit-button
            "
          >
            {{ $t("SentData") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import useValidate from "@vuelidate/core";
import axios from "axios";
import {
  required,
  helpers,
  email,
  minLength,
  maxLength,
} from "@vuelidate/validators";
export default {
  name: "ModalForm",

  data() {
    return {
      isModalVisible: false,
      icon: "iCC-drop-down",
      v$: useValidate(),
      companyName: "",
      companyType: "",
      branches: "",
      name: "",
      adress: "",
      number: "",
      email: "",
      countrys: "",
      StoreTypeErrnew: this.$t("StoreTypeErr"),
    };
  },

  validations() {
    return {
      companyName: {
        required: helpers.withMessage(
          () => this.$t("StoreFullNameErr"),
          required
        ),

        minLength: helpers.withMessage(
          () => this.$t("minlength3"),
          minLength(3)
        ),
        maxLength: helpers.withMessage(
          () => this.$t("maxlength50"),
          maxLength(50)
        ),
      },
      companyType: {
        required: helpers.withMessage(() => this.$t("StoreTypeErr"), required),
        minLength: helpers.withMessage(
          () => this.$t("minlength3"),
          minLength(3)
        ),
        maxLength: helpers.withMessage(
          () => this.$t("maxlength50"),
          maxLength(50)
        ),
      },
      countrys: {
        required: helpers.withMessage(() => this.$t("CityErr"), required),
      },
      branches: {
        required: helpers.withMessage(() => this.$t("BranchesErr"), required),
        maxLength: helpers.withMessage(
          () => this.$t("maxlength2"),
          maxLength(2)
        ),
      },
      name: {
        required: helpers.withMessage(
          () => this.$t("StoreAddressErr"),
          required
        ),
        minLength: helpers.withMessage(
          () => this.$t("minlength3"),
          minLength(3)
        ),
        maxLength: helpers.withMessage(
          () => this.$t("maxlength25"),
          maxLength(25)
        ),
      },
      adress: {
        required: helpers.withMessage(
          () => this.$t("StoreAddressErr"),
          required
        ),
        minLength: helpers.withMessage(
          () => this.$t("minlength3"),
          minLength(3)
        ),
        maxLength: helpers.withMessage(
          () => this.$t("maxlength150"),
          maxLength(150)
        ),
      },
      number: {
        required: helpers.withMessage(() => this.$t("ContactErr"), required),
      },
      email: {
        required: helpers.withMessage(
          () => this.$t("EmailAddressErr"),
          required
        ),
        minLength: helpers.withMessage(
          () => this.$t("minlength3"),
          minLength(3)
        ),
        maxLength: helpers.withMessage(
          () => this.$t("maxlength50"),
          maxLength(50)
        ),
        email: helpers.withMessage(() => this.$t("EmailErr"), email),
      },
    };
  },

  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async sendData() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let result = await axios.post(
          "https://dev.talabatcom.sa/Admin/api/join_us",
          {
            companyName: this.companyName,
            companyType: this.companyType,
            countrys: this.countrys,
            branches: this.branches,
            name: this.name,
            adress: this.adress,
            number: this.number,
            email: this.email,
          },
          {
            Headers: {
              "content-type": "text/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        // sweet alert
        if (result.status == 200) {
         
          localStorage.setItem("user-info", JSON.stringify(result.data));
          Swal.fire("!تم ارسال البيانات بنجاح", " ", "success");
          this.onClose();
          this.onSubmit('3sec');
          
        } else {
          this.v$.$error;
        }
      }
    },
    acceptNumber() {
      var x = this.number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,5})(\d{0,4})/);
      this.number = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    checkKeyDownAlphaNumeric(event) {
      if (!/[a-zA-Zا-ي\s]/.test(event.key)) {
        this.ignoredValue = event.key ? event.key : "";
        event.preventDefault();
      }
    },
    onClose() {
      this.resetInput();
    },
    onSubmit() {
      this.$router.go(this.$router.currentRoute);
    },

    resetInput() {
      this.companyName = "";
      this.companyType = "";
      this.branches = "";
      this.name = "";
      this.adress = "";
      this.number = "";
      this.email = "";
      this.countrys = "";
    },
  },
};
</script>
<style>
.ar-lable {
  justify-content: start;
  direction: rtl;
}
.CC-reverse {
  justify-content: start;

  direction: ltr;
}
/*close icon  */
.CC-head-close {
  top: 10px;
  right: 10px;
  z-index: 1;
}

.error-feedback,
.error {
  color: red !important;
  margin-top: 5px;
  display: flex;
}
.CC-star-toggle {
  width: 7px !important;
  height: 30px !important;
}
.CC-send-button {
  width: 520px;
  height: 56px;
  border: 0;
}
.form-control:focus {
  border-color: #f1ecec !important;
  box-shadow: inset 0 0.2px 0.2px rgba(0, 0, 0, 0.075),
    0 0 2px rgba(231, 226, 226, 0.6) !important;
}
.swal2-popup .swal2-styled.swal2-confirm {
  background-color: #f5504c !important;
}
</style>
<style lang="scss">
.CC-dir-city {
  direction: ltr;
}
.CC-reverse-city {
  direction: rtl;
}
.CC-submit-button {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5504c;
    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(#f5504c, 15%);
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
}
</style>