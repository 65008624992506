<template>
  <div class="overflow-hidden position-relative">
    <!-- footer image -->
    <i class="imCC-footerimg imCC-Background-Cover d-flex CC-bg-holder"> </i>
    <!--  Contact Us Text -->
    <div
      class="
        d-flex
        flex-column
        justify-content-center
        align-items-center
        position-absolute
        CC-footer-join
        uCC-gap-32-plus
      "
    >
      <p
        class="
          fCC-Regular-sCc
          CC-footer-headtext
          cCC-text-secondary
          m-0
          text-center text-md-end
          wow
          animate__animated animate__zoomIn
        "
      >
        {{ $t("haveAstore") }}
      </p>
      <p
        class="
          fCC-Regular-sCc
          CC-footer-headtext
          cCC-text-secondary
          m-0
          text-center text-md-end
          wow
          animate__animated animate__zoomIn
        "
      >
        {{ $t("haveAstore1") }}
      </p>
      <!-- footer buttom -->
      <button
        class="
          uCC-border-f-20
          cCC-bg-main
          align-items-center
          text-center
          border-0
          py-2
          CC-form-buttom
        "
        
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <p class="m-0 fCC-Regular-eB cCC-text-white">{{ $t("JoinUs") }}</p>
      </button>
    </div>

    <!-- footer div holder -->
    <div
      class="
        CC-footer-head
        d-xs-flex
        position-absolute
        cCC-bg-main
        uCC-border-t-15
        px-5
        pb-2
      "
    >
      <!-- Row  -->

      <div class="row CC-Row-column uCC-gap-16">
        <!-- Social Media Icons Holder -->
        <div class="col bottom-50">
          <div
            class="
              d-flex
              uCC-gap-16
              CC-social-media-icons
              justify-content-center justify-content-lg-start
              align-items-center
            "
          >
            <!-- Social Media Icons Array -->
            <div class="" v-for="socical in socicalMediaIcons" :key="socical">
              <a :href="socical.link" target="_blank">
                <i :class="socical.icon" class="iCC-s24 d-flex uCC-Click"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="col d-flex justify-content-center justify-content-lg-end">
          <i
            class="
              d-flex
              mt-lg-3
              CC-talabt-logo
              imCC-Background-Contain
            "
                      :class="$i18n.locale === 'ar' ? 'iCC-talabtkomlogo' : 'iCC-talabtkomlogo-en'"

          ></i>
        </div>
      </div>
      <div
        class="col-12 text-center justify-content-center d-flex m-0 p-0 h-25"
      >
        <div
          class="
            d-md-flex d-none
            align-items-center
            uCC-gap-16
            cCC-text-white
            mb-3
          "
        >
          <p class="fCC-Regular-sA m-0">
            All Rights Reserved <b>LUN</b> Development 2021©
          </p>
          <a href="https://lun.sa/" target="_blank">
            <i
              class="iCC-lunlogo d-flex CC-logo-holder imCC-Background-Cover"
            ></i
          ></a>
          <p class="fCC-Regular-sA m-0 text-nowrap">
            جميع الحقوق محفوظة لشركة لون للبرمجة والتطوير
          </p>
        </div>
        <div
          class="
            d-xl-none d-lg-none d-md-none d-flex
            align-items-center
            uCC-gap-16
            cCC-text-white
            mb-0
          "
        >
          <p class="fCC-Regular-sA m-0 text-nowrap">
            جميع الحقوق محفوظة لشركة لون للبرمجة والتطوير
          </p>
        </div>
      </div>
    </div>
    <!-- Media Query Footer -->
  </div>
</template>
<style>
/* 1- Footer Background Image */
.CC-bg-holder {
  height: 80vh;
  font-style: inherit !important;
}
/* Footer Text & Button */
.CC-footer-join {
  bottom: 30%;
  width: 100%;
  height: 30vh;
}
/* Text Line Height */
.CC-footer-headtext {
  line-height: 70px;
}
.CC-form-buttom {
  width: 200px;
}
/*Start  Footer Main Colore Area*/
/* Main */
.CC-footer-head {
  height: 121px;
  width: 1164px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
/* Talabtkom Logo */
.CC-talabt-logo {
  width: 175px;
  height: 72px;
}
/* Lun Logo */
.CC-logo-holder {
  width: 29px;
  height: 42px;
}
.CC-social-media-icons {
  height: 7vh;
}
@media (max-width: 377px) {
  .CC-footer-join {
    bottom: 38% !important;
    width: 100%;
    height: 30vh;
  }
  .CC-social-media-icons {
    height: 5vh;
  }
}
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .CC-bg-holder {
    height: 500px;
    font-style: inherit !important;
  }
  .CC-footer-join {
    bottom: 30%;
    width: 100%;
    height: 30vh;
  }
  /* Text Line Height */
  .CC-footer-headtext {
    line-height: 40px;
  }
  .CC-footer-head {
    height: 180px;
    width: 400px;
    bottom: 0;
  }
  .CC-Row-column {
    flex-direction: column-reverse;
  }
  .CC-form-buttom {
    width: 150px;
  }
  .CC-talabt-logo {
    width: 130px;
    height: 72px;
  }
  .CC-social-media-icons {
    height: 5vh;
  }
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 769px) {
  .CC-footer-head {
    height: 180px;
    width: 400px;
    bottom: 0;
  }
  .CC-Row-column {
    flex-direction: column-reverse;
  }
  .CC-talabt-logo {
    width: 145px;
    height: 72px;
  }
  .CC-social-media-icons {
    height: 5vh;
  }
  .CC-footer-join {
    bottom: 40%;
    width: 100%;
    height: 30vh;
  }
}
/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .CC-footer-head {
    height: 180px;
    width: 700px;
    bottom: 0;
  }
  .CC-Row-column {
    flex-direction: column-reverse;
  }
  .CC-social-media-icons {
    height: 5vh;
  }
  .CC-talabt-logo {
    width: 140 px;
    height: 72px;
  }
  .CC-footer-headtext {
    line-height: 80px;
  }
  .CC-footer-join {
    bottom: 20%;
    width: 100%;
    height: 35vh;
  }
    .CC-bg-holder {
    height: 600px;
    font-style: inherit !important;
  }
}
/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .CC-footer-head {
    height: 121px;
    width: 900px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .CC-footer-join {
    bottom:-20px;
    width: 100%;
    height: 50vh;
  }
  .CC-bg-holder {
    height: 600px;
    font-style: inherit !important;
  }
  .CC-footer-headtext {
    line-height: 80px;
  }
}
@media (min-width: 1200px){
  .CC-footer-head {
    height: 121px;
    width: 900px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .CC-footer-join {
    bottom:10%;
    width: 100%;
    height: 70vh;
  }
  .CC-bg-holder {
    height: 500px;
    font-style: inherit !important;
  }
  .CC-footer-headtext {
    line-height: 80px;
  }
  .CC-bg-holder {
  height: 80vh;
  font-style: inherit !important;
}
}
</style>
<style lang="scss">
.CC-form-buttom {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5504c;
    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken(#f5504c, 15%);
    transition: all 0.3s;
    border-radius: 10rem;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
}
</style>
<script>
export default {
  name: "FooterTalabtkom",
  data() {
    return {
      socicalMediaIcons: [
        { icon: "iCC-mail", link: "mailto:info@talabatcom.sa" },
        {
          icon: "iCC-instagram",
          link: "https://www.instagram.com/talabatcomapp/",
        },
        {
          icon: "iCC-youtube",
          link: "https://www.youtube.com/channel/UCX1hVmQPrOCSkh9nCyI-3oA",
        },
        { icon: "iCC-twitter", link: "https://twitter.com/talabatcomapp" },
        {
          icon: "iCC-facebook",
          link: "https://www.facebook.com/talabatcomapp",
        },
      ],
    };
  },
};
</script>