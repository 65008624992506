export default {
  "Deliver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver as"])},
  "fast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fast"])},
  "atThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at the"])},
  "lowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lowest"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])},
  "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why"])},
  "Talabatcom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talabatcom  "])},
  "?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["?"])},
  "enjoy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy the experience of preparing the order from any restaurant, café, or shop available in the application,"])},
  "enjoyand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" entering the improved delivery experiences that give you more than your expectations."])},
  "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery quality"])},
  "guarantee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We guarantee you the speed of arrival and determine the expected time when receiving the order from the restaurant."])},
  "Tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Tracking"])},
  "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can track the order on the map from the moment you order until you get it"])},
  "RestaurantsAndMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurants and more"])},
  "applicationProvided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application provided restaurants, cafes, and different stores close to your place and from all available classifications"])},
  "recommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy our recommendations"])},
  "smartRecommendations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The app offers smart recommendations to you based on your restaurant preferences and helps you in new areas"])},
  "UploadTalabatcom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload talabatcom to receive your order ASAP"])},
  "HowDoes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use"])},
  "TalabatcomOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talabatcom offers many features that make delivery more efficient and affordable  "])},
  "TalabatcomOffersand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload now for a unique experience and affordable, Upload now for a unique experience "])},
  "CreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account or login"])},
  "CreateAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account if you are a new user or login if you already have one."])},
  "favoriteRestaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your favorite restaurant"])},
  "availableRestaurants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browse the available restaurants and then choose the restaurant you want to order from to browse all the food and beverages."])},
  "AddToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
  "shoppingCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After you have chosen the meals, they will be added to the shopping cart where you can review the order, and add or cancel meals."])},
  "SendOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Order"])},
  "completeTheOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After completing reviewing the order you will confirm and review your personal data to complete the order"])},
  "OrderTracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Tracking"])},
  "trackTheOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can track the order from the moment the restaurant receives it. Then prepare  and  receive to the driver until the moment he arrives to you through the map"])},
  "ApplicationEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Evaluation"])},
  "evaluate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After receiving the order you can evaluate the whole experience and upload the app."])},
  "Appreciate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appreciate to use our app"])},
  "haveAstore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a store and want to be"])},
  "haveAstore1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" a partner to reach as many users as possible?"])},
  "JoinUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us"])},
  "StoreFullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store full name"])},
  "StoreType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store type"])},
  "City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "chooseCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["choose one"])},
  "Branches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branches no."])},
  "StoreOwnerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store owner name"])},
  "StoreAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store address"])},
  "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact no."])},
  "EmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" E-mail address"])},
  "StoreFullNameErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stroe Name Is Requierd"])},
  "StoreTypeErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store type Is Requierd"])},
  "CityErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City Is Requierd"])},
  "BranchesErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branches no. Is Requierd"])},
  "StoreOwnerNameErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store owner name Is Requierd"])},
  "StoreAddressErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store address Is Requierd"])},
  "ContactErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact no.Is Requierd"])},
  "EmailAddressErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" E-mail address Is Requierd"])},
  "maxlength2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong number"])},
  "minlength3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum 3 letters"])},
  "maxlength25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum 25 letters"])},
  "maxlength50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum 50 letters"])},
  "maxlength150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum 150 letters "])},
  "EmailErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is wrong"])},
  "SentData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "CCright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC-right"])},
  "textend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["newdir"])},
  "mobreve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC-justify-start animate__fadeInRight"])},
  "modaldir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CC-reverse"])},
  "Riyadh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riyadh"])},
  "Jeddah": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeddah"])},
  "Mecca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mecca"])},
  "Medina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medina"])},
  "AlDammām": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al Dammām"])},
  "AlHufuf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al Hufuf"])},
  "Tabuk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabuk "])},
  "KhamisMushait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khamis Mushait"])},
  "Najran": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najran"])},
  "AlMubarraz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al Mubarraz "])},
  "Abha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abha"])},
  "AlJawf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al Jawf"])},
  "Arar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arar"])},
  "Jizan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jizan"])},
  "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iCC-drop-down"])},
  "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than 3 letters"])}
}